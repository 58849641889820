.non-editable-cell {
  justify-content: left !important;
  background-color: #ffffff;
}

.non-editable-cell-light {
  @extend .non-editable-cell;
  background-color: #C0E6F5 !important;
}

.non-editable-cell-dark {
  @extend .non-editable-cell;
  background-color: #83CCEB !important;
}

.editable-cell {
  text-align: left !important;
  background-color: #FAFAB4 !important;
  svg {
    fill: black;
  }
}

.editable-cell-light {
  @extend .editable-cell;
  background-color: #DAF2D0 !important;
}

.editable-cell-dark {
  @extend .editable-cell;
  background-color: #B5E6A2 !important;
}

.pale-red-bg {
  background-color: #F59B9B !important;
}

.right-align {
  justify-content: right !important;
}

.bold-font {
  font-weight: bold !important;
}

.ParentRow {
  background-color: white;
  font-size: large;
  font-weight: bold;
} 

.rg-pane.rg-pane-left {
  z-index: 1;
}

.rg-pane.rg-pane-top {
  z-index: 2 !important;
}

.rg-pane.rg-pane-right.rg-pane-top {
  z-index: 3 !important;
}

.rg-pane.rg-pane-left.rg-pane-top {
  z-index: 3 !important;
}
.rg-cell{
  border-color: teal!important;
}

.header-cell{
  color: white !important;
  font-weight: bold;
  background-color: black !important;
  font-size: large;
  justify-content: left !important;
  border-color: rgb(244, 238, 224);
}


